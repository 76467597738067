<template>
  <nav>
    <ul class="flex items-center justify-center flex-1 max-w-full font-medium text-[#141933] gap-4 navbar">
      <li class="px-1">
        <UPopover :popper="{ strategy: 'absolute' }" :ui="{ container: 'w-max' }" mode="hover">
          <button type="button" class="flex gap-1 items-center hover:underline">Products
            <Icon name="majesticons:chevron-down-line" />
          </button>
          <template #panel>
            <div class="flex gap-4">
              <div class="bg-gray-50 p-4">
                <span class="font-bold">Product</span>
                <ul class="flex flex-col">
                  <li><nuxt-link to="/products?tab=streaming-framework"
                      class="p-2 flex gap-2 items-center hover:bg-gray-200 w-full rounded-md">
                      <Icon name="fluent:chevron-circle-right-12-filled" />Streaming framework
                    </nuxt-link></li>
                  <li><nuxt-link to="/products?tab=rag-pipelines"
                      class="p-2 flex gap-2 items-center hover:bg-gray-200 w-full rounded-md">
                      <Icon name="fluent:chevron-circle-right-12-filled" /> RAG pipelines
                    </nuxt-link></li>
                  <li><nuxt-link to="/developers/user-guide/connect/pathway-connectors"
                      class="p-2 flex gap-2 items-center hover:bg-gray-200 w-full rounded-md">
                      <Icon name="fluent:chevron-circle-right-12-filled" />
                      Connectors
                    </nuxt-link></li>
                </ul>
              </div>
              <div class="p-4 pl-0">
                <span class="font-bold">Choose your deployment</span>
                <ul class="flex flex-col">
                  <li><nuxt-link to="/developers/user-guide/deployment/docker-deployment"
                      class="p-2 flex gap-2 items-center hover:bg-gray-200 w-full rounded-md">
                      <Icon name="fluent:chevron-circle-right-12-filled" />
                      Local
                    </nuxt-link></li>
                  <li><nuxt-link to="/developers/user-guide/deployment/cloud-deployment"
                      class="p-2 flex gap-2 items-center hover:bg-gray-200 w-full rounded-md">
                      <Icon name="fluent:chevron-circle-right-12-filled" />
                      Cloud options
                      <div class="flex gap-2 items-center text-gray-500 ml-2 rounded-full">
                        <Icon name="mdi:aws" class="text-xl" />
                        <Icon name="teenyicons:azure-solid" />
                      </div>
                    </nuxt-link></li>
                </ul>
              </div>

            </div>
          </template>
        </UPopover>
      </li>
      <li class="px-1 hover:underline">
        <nuxt-link to="/pricing">Pricing</nuxt-link>
      </li>
      <li class="px-1">
        <UPopover :popper="{ strategy: 'absolute' }" :ui="{ container: 'w-max' }" mode="hover">
          <button type="button" class="flex gap-1 items-center hover:underline">
            Solutions
            <Icon name="majesticons:chevron-down-line" />
          </button>
          <template #panel>
            <div class="p-4 flex gap-4">
              <ul v-for="(solution, title) in solutions">
                <template v-if="title === 'By topic'">
                  <nuxt-link to="/solutions" class="flex leading-5 hover:bg-gray-100 p-2 rounded-lg w-full ">
                    <span class="font-bold flex gap-2 items-center">
                      <Icon name="fluent:chevron-circle-right-12-filled" />Overview
                    </span>
                  </nuxt-link>
                  <span class="font-bold mb-2 block">By topic</span>
                </template>
                <span v-else class="font-bold mb-2 block">{{ title }}</span>
                <li v-for="item in solution">
                  <nuxt-link :to="item.path" class="leading-5 hover:bg-gray-100 p-2 rounded-lg w-full block">
                    <span class="font-bold flex gap-2 items-center">
                      <Icon name="fluent:chevron-circle-right-12-filled" />{{ item.title }}
                    </span>
                    <span class="text-sm pl-6 text-gray-500 max-w-xs whitespace-normal block">{{ item.description
                      }}</span>
                  </nuxt-link>
                </li>
              </ul>
            </div>
          </template>
        </UPopover>
      </li>
      <li class="px-1 hover:underline">
        <nuxt-link to="/success-stories">Success Stories</nuxt-link>
      </li>
      <li class="px-1">
        <UPopover :popper="{ strategy: 'absolute' }" :ui="{ container: 'w-max' }" mode="hover">
          <button type="button" class="flex gap-1 items-center hover:underline">
            Learn
            <Icon name="majesticons:chevron-down-line" />
          </button>
          <template #panel>
            <div class="p-4 ">
              <ul class="flex flex-col">
                <li><nuxt-link to="/developers/user-guide/introduction/welcome"
                    class="p-2 flex gap-2 items-center hover:bg-gray-200 w-full rounded-md">
                    <Icon name="fluent:chevron-circle-right-12-filled" /> Documentation
                  </nuxt-link></li>
                <li><nuxt-link to="/blog" class="p-2 flex gap-2 items-center hover:bg-gray-200 w-full rounded-md">
                    <Icon name="fluent:chevron-circle-right-12-filled" /> Blog
                  </nuxt-link></li>
                <li><nuxt-link to="/events" class="p-2 flex gap-2 items-center hover:bg-gray-200 w-full rounded-md">
                    <Icon name="fluent:chevron-circle-right-12-filled" />
                    Events
                  </nuxt-link></li>
              </ul>
            </div>
          </template>
        </UPopover>
      </li>
      <li class="px-1">
        <nuxt-link to="/developers/templates" class="hover:underline flex gap-1 items-center">
          <Icon name="heroicons-outline:lightning-bolt" /> App Templates
        </nuxt-link>
      </li>
      <li class="px-1 hover:underline">
        <nuxt-link to="/developers/user-guide/introduction/welcome" class="hover:underline flex gap-1 items-center">
          <Icon name="heroicons-outline:code" /> Docs
        </nuxt-link>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">

const solutions = {
  "By topic": [
    {
      title: "Gen AI for the Enterprise",
      description: "Live data pipelines for AI apps, LLM-powered analytics",
      path: "/solutions/enterprise-generative-ai"
    }, {
      title: "AI RAG pipelines at scale",
      description: "Connect your documents & live data",
      path: "/solutions/rag-pipelines"
    }, {
      title: "ETL for Unstructured Data",
      description: "For AI use cases",
      path: "/solutions/etl-unstructured-data"
    }
  ],
  "Industries and use cases": [
    {
      title: "Contract Management with AI",
      description: "Managing your legal and financial documents in enterprise",
      path: "/solutions/ai-contract-management"
    }, {
      title: "RAG Slides Search Use Case",
      description: "Accurate Slides Search",
      path: "/solutions/slides-ai-search"
    }, {
      title: "AI-enabled vehicles & eSports",
      description: "Telemetry tracking, Augmented reality, Realtime monitoring",
      path: "/solutions/ai-enabled-vehicles-n-esports"
    }, {
      title: "Logistics",
      description: "Postal Services, 3PL, Container Shipping",
      path: "/solutions/logistics"
    }
  ]
}
</script>

<style scoped>
@media (max-width: 1315px) {
  .navbar {
    gap: 0.5rem;
  }
}

@media (max-width: 1025px) {
  .navbar {
    display: none;
  }
}

li {
  white-space: nowrap;
}
</style>
